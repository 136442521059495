import styled from 'styled-components';

const ContentWrapper = styled.div`
  color: #3b3b4d;
  font-family: ${({ theme }) => theme?.fonts?.default};
  iframe {
    width: 100%;
  }
  @media (max-width: 720px) {
    iframe[height='700'] {
      height: calc(100vw - 40px);
    }
  }
  div[style*='border-top:1px solid #dadada'] {
    width: 90%;
  }
  * {
    font-family: inherit;
    font-weight: 500;
  }
  .blog-post-info {
    font-weight: 500;
    font-size: 1rem;
    margin: 1.5rem 0;
  }
  h1 {
    font-size: 30px;
    margin: 24px 0;
    font-weight: 700;
  }
  h2 {
    font-size: 24px;
    font-weight: 700;
    margin: 22px 0;
  }
  h3 {
    font-size: 18px;
    font-weight: 600;
    margin: 22px 0;
  }
  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 2.19;
    margin: 1rem 0;
  }
  p.app-links {
    text-align: center;
  }
  p a img {
    height: 45px;
    margin: 0 10px;
  }
  li {
    line-height: 2.19;
  }
  strong {
    font-weight: 600;
  }
  strong a {
    font-weight: 600;
  }
  img {
    margin: 46px 0;
  }
`;

export default ContentWrapper;
