import React from 'react';
import styled from 'styled-components';

import { Box } from '@core';
import { Text } from '@components';
import { ImageCore } from '@components/image';

const ProfileImage = styled(ImageCore)`
  border-radius: 50%;
`;

const Author = ({ name, logo, description }) => (
  <Box display="flex" alignItems="center" py={24} borderY="2px solid #f1f1f1" key={name}>
    <ProfileImage src={logo} alt={name} width={96} height={96} />
    <Box ml={40}>
      <Text.Body2Strong mb={8}>{name}</Text.Body2Strong>
      <Text.Body4>{description}</Text.Body4>
    </Box>
  </Box>
);

export default Author;
