import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { query } from '@shared/style-helpers';

const Wrapper = styled.div`
  text-align: center;
  margin: 40px 10px 20px 10px;
`;

const Title = styled.p`
  font-size: 18px;
  font-weight: 600;
  margin: 22px 0;
`;

const PostListContainer = styled.div`
  display: flex;

  @media ${query.maxTabletSmall} {
    flex-direction: column;
  }
`;

const PostContainer = styled.div`
  width: 33.3%;
  padding: 12px;

  @media ${query.maxTabletSmall} {
    width: unset;
    margin: 0 12px;
  }
`;

const BlogPost = styled.div`
  border-radius: 15px;
  height: 250px;
  box-shadow: 0 3px 12px 0 #ebebeb;
  background-color: #ffffff;
  padding: 0;
  overflow: hidden;
  transition: 0.25s;
  position: relative;

  &:hover {
    box-shadow: 0 2px 20px 0 #ced3d6;
    margin-top: -5px;
    margin-bottom: 5px;
    transition: 0.25s;
  }
`;

const ImageContainer = styled.div`
  height: 160px;
  overflow: hidden;
`;

const BlogPostImage = styled(GatsbyImage)`
  min-height: 100%;
  width: 100%;
  object-fit: cover;
  margin: 0;
`;

const TitleContainer = styled.div`
  margin: 16px;

  line-height: 28px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
`;

const PostTitle = styled.h3`
  &&&& {
    font-size: 16px;
    font-weight: 600;
    color: #3b3b4d;
    margin: 0;
  }
`;

const RelatedPosts = ({ posts }) => (
  <Wrapper>
    <Title>Related</Title>

    <PostListContainer>
      {posts?.map((post, index) => (
        <PostContainer key={index}>
          <Link to={`/blog${post.node.fields.slug}`}>
            <BlogPost>
              <ImageContainer>
                <BlogPostImage
                  image={getImage(post.node.frontmatter.featuredimage)}
                  alt={post.node.frontmatter.title}
                />
              </ImageContainer>
              <TitleContainer>
                <PostTitle>{post.node.frontmatter.title}</PostTitle>
              </TitleContainer>
            </BlogPost>
          </Link>
        </PostContainer>
      ))}
    </PostListContainer>
  </Wrapper>
);

export default RelatedPosts;
