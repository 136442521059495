import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import { getSrc } from 'gatsby-plugin-image';

import { Layout, SEO } from '@components';
import { callInfogram } from '@shared/blog-helpers';
import { blogTheme } from '@theme';

import BlogPostTemplate from './template/blog-post-template';
import firebaseConfig from '../../../firebase.json';

const getTitle = (post) => {
  return post.frontmatter.metaData?.title || post.frontmatter.title + ' - Atlas VPN';
};

const getDescription = (post) => {
  return post.frontmatter.metaData?.description || post.frontmatter.description || post.excerpt;
};

const redirectedPages = firebaseConfig.hosting.redirects.map((redirects) => redirects.source);

const BlogPost = ({ data, location }) => {
  const post = data.markdownRemark;
  const relatedPosts = data.allMarkdownRemark.edges;
  const url = location.href;
  // TODO This can cause some problems. src of this image can be changed after deploy, so meta image won't be displayed before this page is crawled again, check how it works with share links in production
  const seoImage = getSrc(post.frontmatter.featuredimage);

  useEffect(() => {
    if (document.getElementsByClassName('infogram-embed')[0]) {
      callInfogram(window);
    }
  });

  return redirectedPages.includes(location.pathname) ? null : (
    <ThemeProvider theme={blogTheme}>
      <Layout location={location} footerProps={{ variant: 'light' }}>
        <SEO
          title={getTitle(post)}
          description={getDescription(post)}
          image={seoImage}
          keywords={[post.frontmatter.metaData?.keywords]}
        />

        <BlogPostTemplate
          content={post.html}
          date={post.frontmatter.date}
          title={post.frontmatter.title}
          tags={post.frontmatter.tags}
          description={post.frontmatter.description}
          url={url}
          relatedPosts={relatedPosts}
          isWide={post.frontmatter.isWide}
          banner={post.frontmatter.banner}
          faq={post.frontmatter.components?.find((component) => component.type === 'faq')}
        />
      </Layout>
    </ThemeProvider>
  );
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!, $tags: [String], $language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: { in: ["common", "navigation", "campaigns", "deals", "plans"] }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date
        description
        author
        tags
        featuredimage {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        metaData {
          title
          description
          keywords
        }
        isWide
        banner
        components {
          type
          title
          description
          customQuestions {
            title
            description
          }
          fetchedQuestions
          padding {
            pt {
              _
              sm
            }
            pb {
              _
              sm
            }
          }
        }
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { tags: { in: $tags }, hideInProd: { ne: true } }
        fields: { slug: { ne: $slug } }
      }
      limit: 3
    ) {
      edges {
        node {
          frontmatter {
            featuredimage {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            title
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
