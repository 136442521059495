import React from 'react';

import { Box } from '@core';
import { ContentBlockSection } from '@containers';
import { Shape } from '@components';

const BannerSection = ({ data }) => (
  <Box position="relative" zIndex={1} py={24}>
    <ContentBlockSection
      shape={Shape.Blob4}
      shapeProps={{ left: { _: '10%', md: '50%' }, top: { _: '5%', md: 0 } }}
      {...data}
    />
  </Box>
);

export default BannerSection;
