import React from 'react';
import styled from 'styled-components';

import { SocialShareLinks } from '@components';
import { query } from '@shared/style-helpers';

const Wrapper = styled.div`
  position: sticky;
  top: 40%;
  margin-top: -127px;
  max-width: 80px;
  text-align: right;
  margin-left: -200px;

  @media ${query.maxDesktop} {
    display: none;
  }
`;

const ShareSectionSticky = ({ url, title, description, isColumn }) => (
  <Wrapper>
    <SocialShareLinks shareUrl={url} title={title} description={description} isColumn={isColumn} />
  </Wrapper>
);

export default ShareSectionSticky;
