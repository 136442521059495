import React from 'react';
import { useTranslation } from 'react-i18next';

import { PageContentWrapper } from '@components';
import { BANNER_PRESETS, PROFILES } from '@shared/constants/blog';
import { BodyContent, Heading } from '@templates/shared/components';
import { BodyMarkdownStyleWrapper } from '@shared/markdown-helpers';

import {
  Author,
  BannerSection,
  RelatedPosts,
  ShareSection,
  ShareSectionSticky,
  Tags,
} from './components';
import FAQ from '../../../page-builder/containers/faq';

const BlogPostTemplate = ({
  content,
  date,
  title,
  author,
  tags,
  description,
  url,
  relatedPosts,
  isWide,
  banner,
  faq,
}) => {
  const { t } = useTranslation();

  const authorProfile = PROFILES.find((profile) => profile.id === author);
  const bannerContentData = BANNER_PRESETS(t).find(
    (bannerPreset) => bannerPreset.id === banner && banner !== 'none'
  );

  return (
    <React.Fragment>
      <PageContentWrapper isBlogPost={!isWide} pt={{ _: 30, lg: '2.65rem' }} px={{ _: 20, lg: 0 }}>
        <Heading title={title} author={authorProfile?.name} date={date} />
        <BodyMarkdownStyleWrapper>
          <BodyContent content={content} isWide={isWide} />
        </BodyMarkdownStyleWrapper>
      </PageContentWrapper>
      {bannerContentData && <BannerSection data={bannerContentData} />}
      <PageContentWrapper isBlogPost={!isWide} pb={{ _: 30, lg: '2.65rem' }} px={{ _: 20, lg: 0 }}>
        {authorProfile && <Author {...authorProfile} />}

        {tags && <Tags tags={tags} />}

        {faq && <FAQ {...faq} />}

        {relatedPosts?.length > 0 && <RelatedPosts posts={relatedPosts} />}
      </PageContentWrapper>
    </React.Fragment>
  );
};

export default BlogPostTemplate;
