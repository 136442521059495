import React from 'react';
import styled from 'styled-components';

import { Box } from '@core';
import { Text } from '@components';
import { query } from '@shared/style-helpers';

const Tag = styled.span`
  border-radius: 15px;
  background-color: ${({ theme }) => theme.colors.darkBlue};
  color: #ffffff;
  font-size: 10px;
  line-height: 16px;
  font-weight: 500;

  padding: 4px 12px;
  margin-right: 16px;

  @media ${query.maxTabletSmall} {
    margin: 6px 12px 6px 0;
  }
`;

const Tags = ({ tags }) => (
  <Box display="flex" flexWrap="wrap" alignItems="center" my={{ _: 24, md: 48 }}>
    <Text.Body1 mr={16}>Tags:</Text.Body1>
    {tags.map((tag) => (
      <Tag key={tag}>{tag}</Tag>
    ))}
  </Box>
);

export default Tags;
